.attribute-list {
  border: 1px solid transparent;
    background-color: rgba(236, 169, 19,0.05);
    color: white;
    border-radius: 12px;
    margin-bottom: 16px;
    padding: 16px;
    padding-bottom: 0px;
    padding-top: 6px;
    position: relative;
}
.attribute-list .accordion-header span{
  position: absolute;
  top:14px;
  right:24px;
  color:#AE8E1C;
  margin-top:-12px;
  font-weight:600;
  font-size:24px;
  margin-right:-8px;
}
.response-box .attribute-list .accordion-header span{
  margin-top:-8px;
  }
.attribute-list .accordion-header {
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  text-align: center;
}
.attribute-list .accordion-content {
  display: flex;
  flex-wrap: wrap;
  text-align:left;
}

.attribute-list .column {
  flex: 1;
}

.attribute-list .attribute {

  margin-bottom: 6px;
  font-size:14px;
}

.attribute-list h3{
  margin-bottom: 0px;
    font-family: 'Crimson Text', serif;
    text-decoration: none;
    font-weight: 600;
    font-size: 11px;
    text-transform: uppercase;
    text-align: center;
    color: white;
    opacity: 1;
    margin-bottom: 12px;
    color: #AE8E1C;
    letter-spacing:1px;
}
.attribute-list .attribute-name {
  font-weight: bold;
  margin-right: 0.5em;
  display:block;
  text-transform: uppercase;
  font-size:11px;
  color:#AE8E1C;
  opacity:0.5;
  font-family: 'Roboto', sans-serif;
    font-weight: 700;
    letter-spacing:0.5px;
    word-spacing:2px;
}

.attribute-list .attribute-value {
  flex: 1;
  opacity:0.8;
  font-size:13px;
  font-family: 'Roboto', sans-serif;
    font-weight: 500;
    letter-spacing:0.5px;
    word-spacing:1px;
}
