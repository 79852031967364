@import url('https://fonts.googleapis.com/css2?family=Crimson+Text:ital,wght@0,400;0,600;0,700;1,400&display=swap');

  @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,400&display=swap');

html{background-color: #333;}
body {
  background-color: #333;
  color: #eee;
  font-family: 'Crimson Text', serif;
  font-size: 16px;
  margin: 0;
  padding: 0px;
}
#cc_inputs{
  max-width:580px;
  margin-bottom:0px;
}
#created-character{
  max-width:560px;
  width:100%;
}
@media (min-width: 1140px) {
  #created-character{
    min-width:480px;
  }
}
input[type="radio"] {
    filter: invert(1);
}
p i{
  
}
button{
  cursor:pointer;
}
.image-container{
  position:relative;
}
.large-image {
    border: 2px solid #83662d !important;
  }
  .image-label {
    text-align: center;
    font-size: 16px;
    color: white;
    position:absolute;
    top:238px;
    left:20px;
    opacity: 1;
    margin: auto;
    display: inline;
    max-width: 180px;
    font-family: 'Roboto', sans-serif;
    font-size: 9px;
    font-weight: 900;
    letter-spacing: 0.5px;
    word-spacing: 2px;
    text-transform: uppercase;
    background-color: #83662d;
    color: rgba(255,255,255,0.7);
    padding: 2px;
    padding-left: 6px;
    padding-right: 6px;
    border-radius: 5px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
  }
  .character-image-container .image-label {
top:244px;
  }
.landingpage{
  margin-top:48px;
  margin-bottom:48px;
  text-align:center;
}
.landingpage strong{
  color:#E2BA2C;
}
.landingpage h1{
  width:100%;
  text-align:left;
  text-transform: none;
  font-size:32px;
}
.landingpage h1, .landingpage h2,.landingpage h3,.landingpage h4{
  margin-bottom:12px;
  text-align:left;
}
.landingpage .container{
  max-width:640px;
  padding:6px;
  width:calc(100% - 24px);
}
.landingpage p, .landingpage ul{
  font-family: 'Roboto', sans-serif;
}
.landingpage p{
  text-align:center;
  font-weight:500;
  font-size:16px;
}
.successScreen{
  text-align: center;
}
.row{

}
.row img{
  width:auto;
  max-width:100%;
  border-radius:15px;
  display:inline-block;
  margin:auto;
  margin-top:12px;
  margin-bottom:12px;
}
.usernameBadge{
  position:absolute;
  left:0;
  right:0;
  margin:auto;
  bottom:100px;
  font-family: 'Roboto', sans-serif;
  font-size:14px;
  opacity:0.6;
  text-align: center;
  width:100%;
}
.dalle3Toggle{
  font-family: 'Roboto', sans-serif;
  font-size:14px;
  background-color:black;
  border-radius:20px;
  padding:12px;
  padding-bottom:6px;
  margin-bottom:12px;
  margin-top:12px;
  margin-left:12px;
  margin-right:12px;
}
.dalle3Toggle input{
  width:auto;
  display:inline-block;
  min-width:0px !important;
  width:18px;
  height:18px;
}
.dalle3Toggle label{
  font-size:18px;
}
.plus-badge{
  position:absolute;
  left:6px;
  margin:auto;
  margin-top:-26px;
  
  width:44px;
  padding:6px;
  padding-left:12px;
  padding-right:12px;
  background:rgb(28 135 215) !important;
  border-radius:5px;
  top:32px;
  color:black;
  text-align:left;
  z-index:0;
  font-family:'Roboto', sans-serif;
  font-size:12px;
  font-weight:600;
  opacity:1;
}

.plus-badge span{
  color:black;
}
.plus-badge span svg{
  font-size:10px;
  margin-bottom:-1px;
  margin-top:1px;
  color:#E2BA2C;
  margin-left:1px;
}
.successScreen form, .welcome form, .try-again form{
  width:100%;
  margin:auto;
  text-align: center;
  max-width:100000px;
}
.welcome, .try-again{
  width:100%;
  text-align:center;
}
.toggleForm{
  float:right;
  font-size:11px;
  font-weight:700;
  border:none;
  color:white;
  text-transform:uppercase;
  margin: auto;
    background-color: white;
    border-radius: 2px;
    border: solid 1px transparent;
    padding-left: 6px;
    padding-right: 6px;
    margin-left: 3px;
    margin-right: 3px;
    opacity: 0.3;
    color: black;
    position:absolute;
    right:12px;
    margin-top:-18px;
}
textarea{

}
.textAreaHeader{
  margin-top:24px;
  margin-bottom:6px;
  font-family: 'Roboto', sans-serif;
  font-size:17px;
  font-weight:700;
}
.settingsCog{
  position:absolute;
  opacity:0.7;
  left:16px;
  margin-top:-32px;
  cursor:pointer;
}
.settings-container label{
  font-family: 'Roboto', sans-serif;
  font-weight:600;
}
.settings-btn-container{
  margin-bottom:32px;
}
.settings-btn-container button{
  border:solid 1px white;
  color:white;
  background:transparent;
  border-radius:5px;
  margin:4px;
  font-weight:700;
  cursor:pointer;
  opacity:0.3;
}
.settings-btn-container button.active{
  background-color:#E2BA2C;
  border-color:#E2BA2C;
  color:#333;
  opacity:1;
}
input, textarea,select{
  cursor:pointer;
}
.textDescribe.simple{
  right:200px;
}
.cookiePopup{
  position:fixed;
  top:0px;
  left:0px;
  width:calc(100% - 48px);
  min-height:100%;
  background-color:rgba(0,0,0,0.95);
  text-align:center;
z-index:10000;
padding-left:24px;
padding-right:24px;
}
.cookieModalContent{
  max-width:380px;
  background-color:#333;
  margin:auto;
  margin-top:24px;
  border-radius:10px;
  padding:12px;
  text-align:center;

}
.cookieModalContent h3{
  opacity:0.5;
  font-size:18px;
  margin-top:32px;
  margin-bottom:12px;
}
.cookieModalContent h3 span{
  color:#e2ba2c;
}
.cookieModalContent h1{
  text-align:center;
  font-size:32px;
}
.cookieModalContent p{
  font-family: 'Roboto', sans-serif;
  font-size:16px;
}
.cookie-button-container p{
  font-family: 'Roboto', sans-serif;
  font-size:12px;
  border-top:solid 2px rgba(255,255,255,0.1);
  padding-top:12px;
  opacity:0.6;
}
.form-select.empty{color:rgba(0,0,0,0.33)}
.cookie-button-container button{
  background: #e2ba2c;
    border: none;
    border-radius: 100px;
    padding: 12px;
    padding-left: 24px;
    padding-right: 24px;
    font-weight: 600;
    color: #333;
    width:100%;
    font-size:16px;
}
.modalGen{
  margin: auto;
    margin-bottom: 12px;
    background: #e2ba2c;
    border: none;
    border-radius: 100px;
    padding: 6px;
    padding-left: 24px;
    padding-right: 24px;
    font-weight: 600;
    margin-top: 12px;
    color: #333;
    font-size:16px;
}
.modalGen svg{
  font-size:24px;
  margin-top:-6px;
  margin-bottom:-6px;
  margin-right:-6px;
  margin-left:6px;
}
.modeWarningContent{
  font-family: 'Roboto', sans-serif;
  padding:12px;
  padding-left:24px;
  padding-right:24px;
  margin:auto;
}
.modeWarningContent a{
  margin-top:32px;
  max-width:150px;
}
.dismissButton{
     background: #E2BA2C;
    border: none;
    border-radius: 100px;
    padding: 6px;
    padding-left: 12px;
    padding-right: 12px;
    font-weight: 600;
    margin: auto;
    margin-top: 16px;
    color: #333;
    display: block;
}
.shownAttributesTip{
  font-family: 'Roboto', sans-serif;
}
.generating-text{
  font-family: 'Roboto', sans-serif;
  font-weight:800;
  text-align:center;
  text-transform: uppercase;
  font-size:12px;
  opacity:0.3;
  padding-top:6px;
  padding-right:48px;
}
.settingsCog{

}
.inputWarning p{
  font-size:13px;
  padding-left:12px;
  padding-right:12px;
  font-family: 'Roboto', sans-serif;
}
.topButtonContainer{
  width:100%;
  text-align:center;
  margin-top:6px;
  margin-bottom:18px;
  position:relative;
}
.topButtonContainer p{
  margin:0px;
  font-family: 'Roboto', sans-serif;
  margin:auto;
  font-size:12px;
  margin-bottom:24px;
  font-weight:600;
  opacity:0.6;
}
@media (min-width: 1140px) {
  .topButtonContainer p{
    font-size:19px;
    font-weight:400;
    margin-top:12px;
  }
}
.randomTipContainer{
  position:relative;
  text-align:center;
  margin-top:12px;
  margin-bottom:0px;
}
.randomToolTip{
  position:absolute;
  font-family: 'Roboto', sans-serif;
  margin:auto;
  font-size:10px;
  display:block;
  opacity:0.6;
  top:-48px;
  width:120px;
  right:0px;
  text-align:left;
  padding-top:6px;

}
.modeSelectionContainer{
  background-color: rgba(75,75,75);
  display:inline-block;
  border-radius:100px;
  padding:4px;
}
.topContainer{
  display:block;
  position:relative;
}
.modeSelectionContainer button{
  -webkit-appearance: none;
  background-color:transparent;
  border-radius:100px;
  border:solid 1px transparent;
  padding-left:10px;
  padding-right:10px;
  margin-left:0px;
  margin-right:0px;
  font-size:16px;
  font-weight:700;
  opacity:0.5;
  color:white;
  padding-top:2px;
}
.typeSelectionContainer{
  position:relative;
 text-align: center;

  width:100%;
}

.typeSelectionScrollContainer{
  position:relative;
  display:inline-block;
  width:100%;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none; 
text-align: center;
padding-top:12px;
}
.filters .typeSelectionScrollContainer{
  margin-top:0px;
  padding-top:0px;
}
.typeSelectionScrollContainer button{
  margin:auto;
  background-color:white;
  border-radius:2px;
  border:solid 1px transparent;
  padding:2px;
  padding-left:6px;
  padding-right:6px;
  margin-left:3px;
  margin-right:3px;
  font-size:14px;
  font-weight:700;
  opacity:0.3;
  color:black;
}
.bottom-footer{
  text-align:center;
  margin-bottom:12px;
  font-size:11px;
  font-family: 'Roboto', sans-serif;
  color:#333;
}
.bottom-footer a{
  color:white;
  font-weight:200;
  opacity:0.5;
  padding-left:6px;
  padding-right:6px;
}
.bottom-footer a:hover{
  opacity:0.6;
}
.terms-and-conditions{
  max-width:760px;
  margin:auto;
  margin-bottom:96px;
}
.terms-and-conditions h1{
  font-family: 'Roboto', sans-serif;
  font-size:32px;
  margin-top:64px;
}
.terms-and-conditions h2{
  font-size:18px;
}
.terms-and-conditions p{
  font-family: 'Roboto', sans-serif;
  font-weight:200;
}
.typeSelectionScrollContainer button.active{
  opacity:1;
  color:#333;
  background-color:#AE8E1C;
}
.typeSelectionScrollContainer::-webkit-scrollbar {
  display: none;
}
.topButtonContainer h4{
  margin-top:6px;
  margin-bottom:12px;
  font-size:24px;
  font-weight:600;
}
.viewTypeContainer{
  position:absolute;
  left:6px;
  top:55px;
text-align:left;
display:none;
}
.descriptionTextInput{
  position:relative;
  padding-left:12px;
  padding-right:12px;
  text-align:center;
}
.descriptionTextInput .form-input{
  padding:12px;
  min-width:auto;
  margin:auto;
  text-align:left;
  margin-left:-3px;
  min-height:100px;
  max-width:calc(100% - 72px);
  height:100px;
  border-radius:15px;
  resize:vertical;
  outline:none!important;
-ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  

}
.describeContainer{
  position:relative;
}
.describeContainer.random-loading{
  pointer-events: none;
  opacity:0.2;
}
.describeContainer textarea{
  max-height:1000px;
}
.describeContainer .textDice{
  position:absolute;
  top:8px;
  right:-6px;
  color:#e2ba2c;
  font-size:24px;
  opacity:1;
}
.descriptionTextInput .form-input ::-webkit-scrollbar {
  display: none;
}
.describeContainer .textDice:hover{
  opacity:1;
  cursor:pointer;
}
.descriptionTextInput .form-input::-webkit-input-placeholder {
   font-style: italic;
}
.descriptionTextInput .form-input:-moz-placeholder {
   font-style: italic;  
}
.descriptionTextInput .form-input::-moz-placeholder {
   font-style: italic;  
}
.descriptionTextInput .form-input:-ms-input-placeholder {  
   font-style: italic; 
}
.descriptionTextInput .form-input::placeholder {
  transition: opacity 0.3s;
  opacity: 0.7;
  font-style: italic; 
}

.customChoiceInput::placeholder {
  transition: opacity 0.3s;
  opacity: 0.7;
  font-style: italic; 
}

.descriptionTextInput .form-input.fade::placeholder {
  opacity: 0;
  font-style: italic; 
}
.descriptionTextInput .form-input + p{
  margin:0px;
  text-transform: uppercase;
  font-size:10px;
  font-family: 'Roboto', sans-serif;
  font-weight:700;
  opacity:0.3;
  margin-top:-28px;
  margin-bottom:16px;
  color:black;
}
.modeSelectionContainer button:first-child{
  margin-left:0px;
}
.modeSelectionContainer button:last-child{
  margin-right:0px;
}
.modeSelectionContainer button.active{
  opacity:1;
  background-color:#333;
  color:#e2b714;
}
.inputWarning p b{
  color:#AE8E1C;
}
.characterSpeech{
  text-align:center;
  width:100%;
  display:block;
}
.type-selection{
  font-size:16px;
  padding:6px;
  padding-left:12px;
  padding-right:12px;
  margin-top:0px;
  border-radius:100px;
  margin-bottom:6px;
  background-color:rgba(75,75,75);
  color:#AE8E1C;
  font-weight:600;
  border:solid 2px #AE8E1C;
  text-align:center;
  cursor:pointer;
}
.radioButtons{
  text-align:left;
  width:100%;
  max-width:220px;
  font-size:18px;
  margin:auto;
  margin-bottom:16px;
  color:#AE8E1C;
  min-width:0px;
}
.radioButtons label{
  cursor:pointer;
}
.radioButtons input{
  margin:10px;
  margin-left:8px;
  margin-right:8px;
  margin-bottom:24px;
  margin-top:0px;
  cursor:pointer;
}
.saveBtnContainer{
  text-align:center;
}
.radioButtons button{
  margin:auto;
  margin-bottom:6px;
      background: #e2ba2c;
    border: none;
    border-radius: 100px;
    padding: 6px;
    padding-left: 12px;
    padding-right: 12px;
    font-weight: 900;
    margin-top: 12px;
    color: #333;
}
.experimentalLabel{
  margin-top:-18px;
  margin-bottom:12px;
  opacity:0.5;
  font-style:italic;
  font-family: 'Roboto', sans-serif;
    font-size:11px;
}
.modeTitle{
  opacity:0.5;
  margin:auto;
  margin-right:20px;
  margin-top:-30px;
  margin-bottom:17px;
  display:inline;
  max-width:180px;
  font-family: 'Roboto', sans-serif;
  font-size:9px;
  font-weight:900;
  letter-spacing:0.5px;
  word-spacing:2px;
  text-transform:uppercase;
  background-color:#2c2f33;
  color:rgba(255,255,255,1);
  padding:2px;
  padding-left:6px;
  padding-right:6px;
  border-radius:5px;
}
.slide .modeTitle{
  position:absolute;
  width:auto;
  min-width:160px;
  margin:0px;
  z-index:1;
  top:246px;
  right:12px;
}
.character-image-container .modeTitle{
  position: absolute;
  margin-top: -38px;
  right: -5px;
}
.viewAllButton{
      background: #E2BA2C;
    border: none;
    border-radius: 100px;
    padding: 6px;
    padding-left: 12px;
    padding-right: 12px;
    font-weight: 600;
    margin: auto;
    font-family:'Roboto', sans-serif;
    font-size:13px;
    color: #333;
    cursor:pointer;

}
.textSearch{
  margin-top:12px;
  position:relative;
}
.textSearch input.searched{
  opacity:0.4;
}
.textSearch svg{
  margin-top:3px;
  margin-bottom:-3px;
  position:relative;
margin-left:6px;
cursor:pointer;
}
.textSearch svg:hover{
  color:#E2BA2C;
}
.textSearch input{
  width:100%;
  max-width:180px;
  padding: 3px;
    border-radius: 5px;
    border: solid 1px transparent;
    padding-left: 6px;
    padding-right: 6px;
    font-weight: 700;
    outline:none;
}
.cardModalOverlay{
  position:absolute;
  top:24px;
  left:0px;
  z-index:100;
  width:100%;
  height:10000px;
  background-color:rgba(10,10,10,0.96);
  text-align:center;

}
.cardModalContent{
  margin:auto;
  background-color:transparent;
  max-width:640px;

  padding-bottom:12px;
  margin-top:52px;
  border-radius:15px;
}
p.error{
  margin-top:12px;
}
.filter-toggle, .chatDetailToggle{
  
  position:relative;
  display:inline-block;
  margin:auto;
  width:auto;
  cursor:pointer;
      background: transparent;
    color: white;
    border: none;
    padding-left: 12px;
    padding-right: 12px;
    border-radius: 15px;
    font-weight: 700;
    text-transform: lowercase;
    font-size: 14px;
    margin: auto;
    margin-top:12px;
    margin-bottom: 6px;
    opacity: 0.7;
}
.chatDetailToggle{
  text-transform: lowercase;
      margin-top: 24px;
    margin-bottom: 12px;
    opacity: 0.5;
    font-family: 'Roboto', sans-serif;
  margin:0px;
  margin-top:24px;
  margin-bottom:8px;
}
#copyButton{
  position:absolute;
  right:12px;
  opacity:0.4;
  top:6px;
}
#copyButton:hover{
  cursor:pointer;
  opacity:1;
}
.dashboard #copyButton{
  left:12px;
  right:auto;
  top:6px;
  z-index:9;
}
#copyButton.centerMe{
  left:0;
  right:0;
}

.character-image-container2 .modeTitle{
  position: absolute;

  right:0px;
  top:264px;
  max-width:180px;
  width:fit-content;
}
.page-cta{
  text-align: center;
  font-family: 'Roboto', sans-serif;
    font-size:14px;
    display:none;
}
.character-image-container,.character-image-container2{
  position:relative;
  margin:auto;
  width:260px;
}
.character-image-container.image-loading{
  opacity:0.3;
  pointer-events:none;
}
.image-refresh, .description-refresh, .image-style, .image-trash{
    cursor:pointer;
      position: absolute;
    top: 24px;
    right: -28px;
    border: solid 2px #333;

    background: #333;
    padding: 2px;
    color: white;
    opacity:0.7;
    background:transparent;
  border-color:transparent;
}
.character-paragraph .description-refresh{
  background:transparent;
  border-color:transparent;
}
.image-style{
  top: 58px;
}
.image-trash{
  top: 58px;
}
.description-refresh{
  top:6px;
  right:6px;
}
.composer-container{
  display:flex;
  position:relative;
  margin:auto;
  margin-bottom:24px;
}
.get-property{
    font-size: 24px;
    border-radius: 5px;
    color: #E2BA2C;
    border: none;
    padding: 0px;
    cursor: pointer;
    z-index: 99;
    margin-top:22px;
    margin-left:-6px;
    margin-right:12px;
    opacity:0.9;
}
.name-loading .form-input{
  pointer-events: none;
  opacity:0.5;
}
.inputs-column-full-width.flex{
  display:flex;
  grid-gap: 0px;
}
button{
  cursor:pointer;
}
.detailsButtonContainer{
  text-align:center;
  margin-top:-6px;
  margin-bottom:6px;
}
.add-more-details{
margin:auto;

  background:#E2BA2C;
  border:2px solid #E2BA2C;
  border-radius:500px;
  font-size:14px;
  padding:6px;
  padding-left:24px;
  padding-right:24px;
  font-weight:900;
  color:#333;

  word-spacing:1px;
}
.add-more-details.top{
  margin-bottom:12px;
  margin-top:0px;
  font-size:14px;
  padding:2px;
  padding-left:12px;
  padding-right:12px;
}
.lifePathDisplayContainer{
  background-color: #3D3D3D;
    border-radius: 10px;
    padding: 12px;
    margin:12px;
}
.add-more-details:hover{

}
.add-more-details.top:hover{

}
.get-property:hover{

}
.get-property.disabled{
  pointer-events:none;
  background-color:rgba(0,0,0,0.6);
}
.dashboard{
  text-align: center;
  min-height:512px;
}
.chat .dashboard{
  min-height:0px;
}
.center-text p{
  text-align: center;
}
.center-me{text-align: center;}
.link-button{
  border-radius: 100px;
    border: none;
    padding: 4px;
    padding-left: 24px;
    padding-right: 24px;
    background-color: #AE8E1C;
    font-weight: bold;
    color: #333;
    display:inline-block;
    margin-top: 12px;
    font-size:13px;
    font-family: 'Roboto', sans-serif;
}
.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
 max-width: 1440px;
 margin:auto;
overflow:hidden;
}
.clear-filters{
  background:transparent;
  border:none;
  outline:none;
  font-weight:700;
  color:white;
  text-decoration:underline;
  padding:0px;
  margin-top:12px;
  opacity:0.7;
}
.clear-filters:hover{
  opacity:1;
}
#see-more-inline{
  font-size:19px;
}

form, .leftColumn{
  max-width:600px;
  width:100%;
  position: relative;
}
@media (min-width: 1140px) {
  form, .leftColumn{
    min-width:600px;
  }
}
.add-more-modal-content form, .modal form{
  max-width:480px;
  min-width:0;
}

.invalid-image{
  position:relative;
}

.invalid-image img{
  
}

.invalid-image p{
  position: absolute;
  top:calc(25% );
  left:calc(25% );
  right:25%;
  color:pink;
  font-weight:600;

}
.invalid-image .portrait{

}
.sign-up-form-container h2{
  font-size:24px;
}

.character-name{
  margin:0px;
  margin-bottom:12px;
  margin-top:12px;
  text-shadow: 1px 1px 1px rgba(0,0,0,0.5);
  font-family: 'Crimson Text', serif;
  text-decoration: none;
  opacity:1;
  font-size:24px;
}

.form-section {
  display: block;
  flex-direction: row;
  align-items: center;
  margin: 20px 0;
  width: 100%;
  border-bottom:solid 1px rgba(255,255,255,0);
  padding-bottom:0px;
  margin-top:0px;
  margin-bottom:10px;
}
.form-select.loading, .form-input.loading {
  opacity: 0.5;
  cursor: not-allowed;
}
.form-section input, .form-section textarea{
  width:calc(100% - 10px);
}

.form-title{
  position: absolute;
  text-align: left !important;
  margin-top:12px;
}
.output-container .form-title{
  margin-top:-24px;
  font-family: 'Crimson Text', serif;
  font-weight:600;
}
.output-container{
  padding:12px;
  padding-top:32px;
  background-color: black;
  margin-top:12px;
  border-radius: 5px;
  border: 2px solid rgba(255,255,255,0.5);
    background-color: rgba(255,255,255,0.2);
    border-radius: 5px;
  display:none;
}

.output-options{
  display:flex;
}
.output-options label{
  width:100%;
  margin-top:6px;
      font-family: 'Roboto', sans-serif;
    font-size: 11px;
}
.layout-button-container{
  font-family: 'Roboto', sans-serif;
  position:absolute;
  top:12px;
  right:225px;
  font-size:10px;
  letter-spacing: 0.5px;
    font-weight: 700;
    text-align: left;
        border: 2px solid #2c2f33;
    background-color: #2c2f33;
    padding: 4px;
    border-radius: 10px;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 8px;
    width:97px;
    max-height:38px;
    cursor:pointer;
    color:white;

}
.layout-button-container label{
  display:block;
  max-width:82px;
  line-height:11px;
  letter-spacing:0.5px;
}
.layout-button-container:hover, .layout-button-container:hover label{
  cursor:pointer;
  color:#AE8E1C;
}
.toggle-columns-container{
  font-family: 'Roboto', sans-serif;
position:absolute;
top:12px;
  right:135px;
  font-size: 10px;
    font-weight: 700;
    text-align: left;
        border: 2px solid #2c2f33;
    background-color: #2c2f33;
    padding: 4px;
    border-radius: 10px;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 8px;
    width:68px;
    max-height:38px;
    cursor:pointer;
    color:white;
    letter-spacing:0.5px;
    word-spacing:2px;
}
.toggle-columns-container:hover, .toggle-columns-container:hover label{
  cursor:pointer;
  color:#AE8E1C;
}
.toggle-columns-container label{
  opacity:0.9;
}
.toggle-columns, .change-layout{
  position: absolute;
  top:8px;
  right:4px;
  font-size:20px;
  opacity:0.9;
}
.change-layout{
  right:2px;
}
input[type="checkbox"] {
  filter: invert(1);
}
.inputs-columns{
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 8px;
  width:100%;
}
.inputs-columns.single-column{
  grid-template-columns: 1fr;
  grid-gap:0px;
}
.single-column .input-dice{
  position:absolute;
  bottom:-31px;
  right:0px;
}
.single-column .form-select, .single-column .form-input{
  width:calc(100% - 40px)
}
.single-column .form-input{
  width:calc(100% - 56px)
}
.single-column .form-label{
  display:block;
  position:relative;
  width:100%;
}

.inputs-columns.custom{
  grid-template-columns: 1fr;
}
.custom-field-wrapper {
  display: flex;
  align-items: center;
}

.remove-custom-field-btn {
  background-color: transparent;
  border: none;
  color: white;
  font-weight: bold;
  cursor: pointer;
  padding-top:10px;
  margin-left:-8px;
  opacity:0.6;
}
.remove-custom-field-btn:hover{
  opacity:1;
  cursor:pointer;
}
.inputs-column-full-width{
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 8px;
  width: 100%;
  margin-bottom:0px;
  padding-left:10px;
  padding-right:10px;
}
.inputs-column-full-width.flex{
  width: calc(100% - 20px);
  padding-left:10px;
  padding-right:10px;
}
.infoHeading{
  margin:0px;
  margin-top:0px;
  margin-bottom:6px;
  text-align:center;
  font-size:14px;
  width:100%;
}
.infoHeading.formHeading{
  margin-top:-6px;
  font-size:14px;
  opacity:0.6;
}
.infoHeading.enterDetails{
  margin-top:0px;
  margin-bottom:12px;
  font-weight: 600;
  font-size:17px;
font-family: 'Roboto', sans-serif;
}
.infoHeading b{

}
.columnViewButton{
  text-transform: uppercase;
  cursor:pointer;
      font-size: 10px;
    font-weight: bold;
    font-family: 'Roboto', sans-serif;
    display: inline-block;
    margin-left: 6px;
    opacity: 0.7;
    text-align:left;
}
.attributesLinkedCreation{
  
}
.attributesLinkedCreation div{
  text-align: center;
  margin-top:8px;
}
.attributesLinkedCreation img{
  width:150px;
  border-radius:15px;
  
}
.tags.random-loading{
  pointer-events: none;
  opacity:0.2;
}
.tagsModal{
  max-width:300px;
  margin:auto;
}
.tagsModal .makeNewTag{
  color:white;
  font-size: 14px;
    padding: 3px;
    padding-left: 12px;
    padding-right: 12px;
    font-weight: 900;
    outline:none;
    border:none;
    background:transparent;
    opacity:0.6;
    margin-bottom:12px;
}
.tagsModal .deleteTag{
  color:white;
  font-size: 14px;
    padding: 3px;
    padding-left: 12px;
    padding-right: 12px;
    font-weight: 900;
    outline:none;
    border:none;
    background:transparent;
    opacity:0.6;
}
.tagsModal .deleteTag:hover{
  opacity:1;
}
.existingTags{
  margin-top:32px;
}
.existingTags h4{
  font-family: 'Roboto',sans-serif;
  opacity:0.7;
  font-size:12px;
  margin-bottom:12px;
}
.tagsModal .saveTag{
    margin: auto;
    background: #E2BA2C;
    border: 2px solid #E2BA2C;
    border-radius: 500px;
    font-size: 14px;
    padding: 3px;
    padding-left: 12px;
    padding-right: 12px;
    font-weight: 900;
    color: #333;
}
.tagsModal h3{
  margin-bottom:12px !important;
}
.tagsModal p{
  font-family: 'Roboto',sans-serif;
  font-size:14px;
}
.tagsModal input, .tagsModal textarea{
  min-width:200px;
  margin-bottom:12px !important;
  max-width:280px !important;
  width:280px;
  padding: 3px;
    border-radius: 5px;
    border: solid 1px transparent;
    padding-left: 6px;
    padding-right: 6px;
  outline:none;
  font-weight: 700;
}
.tagsSettings{
  width:calc(100% - 32px);
  margin-top:0px;
  margin-bottom:0px;
  padding-left:12px;
  padding-right:12px;
  text-align:center;
  display:none;
}
.tagsSettings .form-label{

}
.TagsContainer{
  max-height:75px;
  max-width:100%;
  position:relative;
  overflow-x: hidden;
}
.TagsContainer.slider{

}
.TagsContainer::-webkit-scrollbar {
  background-color: rgba(255,255,255,0.2);
  width:2px;
  height:2px;
  border-radius:50px;
}

.TagsContainer::-webkit-scrollbar-thumb {
  background-color: rgba(255,255,255,0.2);
  border-radius:50px;

}
.tagsSlider{
  width:100%;
  position:relative;

    white-space: wrap;
    -ms-overflow-style: auto;  /* IE and Edge */
    scrollbar-width: auto;
}
.TagsContainer button{
  opacity:0.7;
  border:solid 1px rgba(255,255,255,0.5);
  padding:2px;
  padding-left:8px;
  padding-right:8px;
  background:transparent;
  color:rgba(255,255,255,0.7);
  font-weight:500;
  outline:none;
  cursor:pointer;
  border-radius:10px;
  margin:3px;
  font-size:11px;
}
.TagsContainer button.addNew{
  color:black;
  background:white;
  opacity:0.25;
}
.TagsContainer button.addNew:hover{
  background:#e2ba2c;
  border:solid 1px transparent;
  opacity:1;
}
.TagsContainer button.active{
  background:#e2ba2c;
  border:solid 1px transparent;
  color:rgba(0,0,0,0.7);
}
.displayTags{
  padding-bottom:10px;
}
.displayTags .TagsContainer button{
  pointer-events: none;
  opacity:0.7;
}
.visibilitySettings{
  padding-left:6px;
  padding-right:6px;
    font-size:13px;
  font-weight:bold;
  opacity:0.7;
  margin-top:15px;
}
.visibilitySettings label:last-child{margin-left:12px}
.flexMe{
  display:flex;
   justify-content: center;
   gap:24px;
   margin-bottom:12px;
}
.flexMe button, .textDescribe{
  border-radius:10px;
  border:none;
  padding:8px;
  padding-left:16px;
  padding-right:16px;
  display:flex;
  align-items:center;
  background-color:#333;
  color:#AE8E1C;
}
.flexMe button.randomize-container{
  color:rgba(255,255,255,0.7);
  padding:6px;
  padding-left:12px;
  padding-right:12px;
  font-size:11px;
}
.flexMe button.randomize-container label{
  text-align:left;
  padding-left:6px;
  cursor:pointer;
}
.textDescribe{
  margin:auto;
  margin-top:24px;
  margin-bottom:24px;
  font-size:15px;

}
.textDescribe svg{
  color:#e2ba2c;
}
.textDescribe.quickOff svg{
  color:rgba(255,255,255,0.7);
}
.textDescribe.simple{
  color:rgba(255,255,255,0.7);
  font-weight:700;
}
.textDescribe.simple b{
  color:#AE8E1C;
}
.flexMe button svg{
  color:#e2ba2c;
  font-size:24px;
  margin-right:3px;
}
.accordion{
  width:calc(100% - 20px);
  display:block;
  padding-left:10px;
  padding-right:10px;
}

.accordion-label{
  text-align: left;
  font-family: 'Roboto', sans-serif;
    text-decoration: none;
    font-weight: 900;
  text-decoration: none;
  font-size: 10px;
  letter-spacing:0.5px;
  word-spacing:2px;
  text-transform: uppercase;
  color: white;
  opacity: 1;
  color: white;
  margin-bottom:10px;
  padding:0px;
  padding-left:8px;
  padding-right:8px;
  background-color:rgba(255,255,255,0.1);
}

.accordion {
  margin-bottom: 12px;
}

.accordion-label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  background-color: #2c2f33;
  color: rgba(255,255,255,0.7);
   
  border-radius: 5px;
  opacity:1;
}

.carousel.carousel-slider{
}
.carousel .slide{

}
.carousel .slider-wrapper{
  min-width:1000px;
  pointer-events: none;
}
.chatButton{
  margin: auto;
    background: #E2BA2C;
    border: 2px solid #E2BA2C;
    border-radius: 500px;
    font-size: 14px;
    padding: 6px;
    padding-left: 24px;
    padding-right: 24px;
    font-weight: 900;
    color: #333;
    word-spacing: 1px;
        margin-bottom: 12px;
    font-size: 10px;
    text-transform: uppercase;
    padding: 2px;
    padding-left: 12px;
    padding-right: 12px;
}
.quickModeContainer{
  position:relative;
  text-align: center;
  padding-top:6px;
  padding-bottom:6px;
  margin-bottom:24px;
  opacity:0.9;
}
.formSpinnerContainer{
  text-align:center;
}
.quickModeContainer:hover{
  opacity:1;
}
.buttonBase{
  border-radius:10px;
  border:none;
  padding:4px;
  padding-left:16px;
  padding-right:16px;
  align-items:center;
  background-color:black;
  font-weight:700;
  opacity:0.6;
  color:rgba(255,255,255,0.7);
}
.buttonBase.highlighted{
  color:#AE8E1C;
  opacity:1;
}
.buttonBase:first-child{
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.buttonBase:last-child{
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
.buttonBase svg{
  margin-bottom:-2px;
}
.slide-inner{
  position: relative;
  height: 512px;
  padding-left:6px;
  padding-right:6px;
  text-align: left;
  min-width:250px !important;
  max-width:250px !important;
}
.carousel .thumbs-wrapper {
  overflow: visible !important;
}
.carousel .control-arrow, .carousel.carousel-slider .control-arrow{
  opacity:1;
}
.carousel.carousel-slider .control-arrow:hover{
  background-color: transparent !important;
}
.carousel .thumbs {
  display: flex;
  flex-wrap: nowrap;
  overflow: visible !important;
  padding-bottom: 40px;
}
.carousel .slide .slide-inner img{
      border: 2px solid transparent;
    border-radius: 20px;
    width: 100%;
    margin-bottom:16px;
}
.slide-inner .description-container h3{
  font-size:11px;
  margin:0px;
  margin-top:6px;
  text-align: center
}
.slide-inner{
  text-align:center;
}
.slide-inner h3{
  text-align:left;
  margin-top:0px;
  margin-bottom:6px;
  min-height:25px;
}
.slide-inner .character-paragraph h3{
  min-height:0px;
}
.fade-gray{
  position:absolute;
  width:100%;
  height:530px;
  top:0px;
  left:0px;
  background: linear-gradient(to bottom, transparent, rgba(51, 51, 51, 0.1) 70%, rgba(51, 51, 51, 1));
}
.dashboard-characters .fade-gray, .characters-page .fade-black-characters{
    position: absolute;
    width: 100%;
    height: 65px;
    top: auto;
    bottom: 0px;
    left: 0px;
    border-bottom-left-radius: 10px;
    background: linear-gradient(to bottom, transparent, rgba(51, 51, 51, 0.1) 0%, #3D3D3D 50%);
    border-bottom-right-radius: 10px;
}
.toggle-button {
  padding:0px;
  background-color: transparent;
  color: #AE8E1C;
  font-size: 1.25rem;
  font-weight: bold;
  border: none;
  outline: none;
  cursor: pointer;
}
.lifePathInputsGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  margin-bottom:48px;
  font-family: 'Roboto', sans-serif;
  font-size:14px;
  padding-left:12px;
  padding-right:12px;
  margin-top:32px;
}
.lifePathOptionsSection{
  margin-top:32px;
font-family: 'Roboto', sans-serif;
  font-size:14px;
}
.lifePathOptionButton{
  font-family: 'Roboto', sans-serif;
  font-size:14px;
  font-weight:500;
  padding:6px;
  border-radius:5px;
  border:solid 3px #333;
      background-color: #333;
    color: rgba(255,255,255,0.7);
}
.lifePathOptionsContainer .lifePathOptionButton{
  font-style: italic;
  font-size:15px;
}
.lifePathOptionButton:hover{
  color: rgba(255,255,255,1);
}
.regenTip{
  opacity:0.7;
  font-style:italic;
  font-size:13px;
  max-width:300px;
  margin:auto;
  margin-top:16px;
  margin-bottom:0px;
}
.lifePathOptionButton.more{
  color:rgba(255,255,255,0.7);
  background:#333;
  border-color:#333;
  font-weight:700;
  border-radius:100px;
  padding-left:12px;
  padding-right:12px;
  margin-top:6px;
  margin-bottom:6px;
  opacity:1;
}
.lifePathOptionButton.more:hover{
  color:#e2ba2c;
}
.lifePathOptionButton.more svg{
  margin-top:1px;
  margin-bottom:-1px;
  margin-right:2px;
}
.finishLifePath{
  color:#333;
  background:#e2ba2c;
  border-color:#e2ba2c;
  font-weight:700;
  border-radius:100px;
  padding-left:12px;
  padding-right:12px;
  border:solid 1px transparent;
  padding:6px;
  padding-left:24px;
  padding-right:24px;
  font-size:16px;
}
.customChoiceInput{
  width:calc(100% - 48px);
  min-width:calc(100% - 200px);
  max-width:360px;
  margin:12px;
  margin-top:0px;
  margin-bottom:0px;
  border:solid 2px #333;
  max-height:500px;
  min-height:100px;
  padding: 12px;
  border-radius: 15px;
    resize: vertical;
    outline: none!important;
    font-size:15px;
}
.decision-submit-flexbox{
  display:flex;
  max-width:270px;
  margin:auto;
  margin-bottom:18px;
}
.lifePathInput {
text-align:left;

}
.lifePathOptionButton.custom{
  color:#333;
  background:#e2ba2c;
  border-color:#e2ba2c;
  font-weight:700;
  border-radius:100px;
  padding-left:12px;
  padding-right:12px;
  margin-bottom:6px;
  margin-top:6px;
}
.lifePathOptionButton{
  display:block;
  margin:auto;
  margin-bottom:6px;
  max-width:400px;
}
.lifePathCustomOption{
  display:block;
  position:relative;
}
.lifePathOptionsContainer{
  max-height:1024px;
  margin-bottom:10px;
}
.button-container-fixed {
  position: fixed;
  bottom: -12px;
  left: 0;
  right: 0;
  background-color: #333;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index:100;
}
.button-container-fixed .form-button{
  max-width:480px;
}
.form-label {
  font-size: 15px;
  font-weight: bold;
  margin-right: 20px;
  min-width: 96px;
  text-align: left;
  opacity:0.9;
  position: relative;
  margin-bottom:2px;
}
.input-dice{
  font-size:24px;
  float:right;
  margin-bottom:2px;
  margin-right:4px;
  margin-left:-4px;
  margin-top:-4px;
  color:#e2ba2c;
  margin-bottom:4px;
  outline:none;
}

.custom-field-wrapper .input-dice{
  margin-right:6px;
}
.terms-container{
  padding:12px;
}
.input-dice:hover{
  cursor:pointer;
  color:white;
}
.clearForm{
  position:absolute;
  right:12px;
  bottom:-32px;
  font-size: 10px;
    font-family: 'Roboto', sans-serif;
    color: #AE8E1C;
    padding: 0px;
    font-weight:600;
    background:transparent;
    border:none;
    text-align:right;
}
.clearContainer{
  position:relative;
  display:block;
  width:100%;

}
.form-input,
.form-select {
  border: 2px solid #333;
  border-radius: 5px;
  color: #333;
  font-size: 15px;
  padding: 3px;
  width: 100%;
  background-color: #ddd;
  
}
.form-select{
}
.form-input.filled, .form-select.filled{
  border: 2px solid #333;
}
.submit-button-container{
  position:fixed;
  width:100%;
  bottom:0px;
  background-color:#333;
  height:64px;
  padding:0px;
  left:0px;
  z-index:100;
  text-align:center;
  display:none;
}
.submit-button-container button{
  margin:auto !important;
  margin-top:10px !important;
  max-width:360px;
}
.form-button.desktop-submit{
  margin:auto;
  margin-bottom:12px;
}
.save-character{
  float:right;
  color:#AE8E1C;
  border:solid 2px;
  border-radius:10px;
  background-color:#333;
  border-color: #333;
  position: absolute;
  right:6px;
  top:6px;
  font-size:15px;
  font-weight: 600;
  padding-left:12px;
  padding-right:12px;
  opacity:0.4;
  display:none;
}
.save-character:hover{
  opacity:1;
}
.save-character.bottom{
  bottom:6px;
  right:12px;
  top:auto;
  position: absolute;
}
.edit-character{
  float:left;
  color:#AE8E1C;
  border:solid 2px #333;
  border-radius:10px;
  border-color: #333;
  position: absolute;
  font-size:15px;
  font-weight: 600;
  left:6px;
  top:6px;
  background-color: #333;
  padding-left:12px;
  padding-right:12px;
  opacity:0.4;
}
.edit-character:hover{
  opacity:1;
}
.edit-character.bottom{
  bottom:6px;
  left:12px;
  top:auto;
  position: absolute;
}
.chat-character{
  margin-top:12px;
  border-radius:200px;
  border:solid 2px #AE8E1C;
  opacity:0.2;
  pointer-events:none;
}
.call-to-action{
  margin-top:32px;
  font-family: 'Roboto', sans-serif;
  font-size:12px;
}
.form-button {
  background-color: #e2ba2c;
  border: 2px solid #e2ba2c;
  border-radius: 50px;
  color: #333;
  cursor: pointer;
  padding: 10px;
  text-align: center;
  margin:auto;
  font-size:19px;
  font-weight:600;
  padding-left:24px;
  padding-right:24px;
}
.form-button:hover{

}

.section{
  width:100%;
  display:block;
  margin:auto;
  margin-bottom:48px;
}
.chat .section{ 
margin-bottom:0px; }
.likesContainer{
  position:absolute;
  right:6px;
  top:6px;
  z-index:1;
}
.likesContainer span{
  font-family:'Roboto';
  font-weight:700;
  font-size:13px;
  position:absolute;
  right:20px;
  text-align:right;
  opacity:0.7;
  top:0px;
  -webkit-user-select: none;
        -webkit-touch-callout: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
}
.cardModalContent .likesContainer span{
  display:none;
}
.likesContainer svg{
  cursor:pointer;
  opacity:0.35;
}
.likesContainer svg:hover{
  opacity:1;
}
.likesContainer.liked svg{
  opacity:1;
}
.recent-creations.section{
  max-width:1024px;
}
.viewChanger{
  margin-top:32px;
  font-family:'Crimson Text', serif;
  background:transparent;
  border:none;
  outline:none;
  color:white;
  font-size:24px;
  font-weight:700;
  -webkit-appearance: none;
        appearance: none;
}
h1 {
  font-size: 15px;
  letter-spacing: 1px;
 
  text-transform:uppercase;
  text-align: left;
  margin: 0px;
  padding-top:0px;
  padding-bottom:0px;

}

h2 {
  font-size: 24px;

  margin-bottom: 20px;

}

.character-description {
  background-color: #ddd;
  border: 2px solid #AE8E1C;
  border-radius: 5px;
  margin-left: 20px;
  padding: 20px;
  width: 400px;
}

.response-section {
  display: inline-block;
  width:100%;
  max-width:475px;
  margin:12px;
  margin-right:12px;
  margin-left:0px;
  position: relative;
  text-align: center;
}
#created-character.disable{
  pointer-events: none;
  opacity:0.2;
}
.response-inner{
  position:relative;
  border: 1px solid rgba(75,75,75);
  background-color: rgba(75,75,75);
  border-radius:10px;
  padding:12px;
  padding-bottom:24px;
  margin-bottom:48px;
}
@media (max-width: 1024px) {
  .response-inner{
    padding-left:3px;
    padding-right:3px;
}
}
.response-box {
  padding: 6px;
  border-radius: 20px;
  text-align: center;
  font-size:16px;
  font-weight: 600;
  text-align: left;
  padding-top:0px;
  padding-bottom:0px;

}
.token-container{
text-align: right;
}
.token-display{
margin:auto;
position:absolute;
top:6px;
right:125px;
font-size:13px;
font-weight:bold;
opacity:0.8;
}

.token-display svg{
  margin-bottom: -3px;
    margin-left: -3px;
}
.success-message{
  text-align:center;
  color:white;
  font-family: 'Roboto', sans-serif;
  text-transform: none;
  padding-top:48px;
  padding-bottom:32px;
}
p.generic-message{
  text-align: center;
  color:white;
  font-family: 'Roboto', sans-serif;
    display:block;
    padding-top:32px;
    padding-bottom:12px;
    font-weight:600 !important;
    max-width:260px !important;
    margin:auto;
    margin-bottom:12px !important;
    font-size:18px !important;
    color:#E2BA2C !important;
}
.hero-banner .modal-content p{
  opacity: 1;
  font-size:14px;
  max-width:300px !important;
  margin:auto;
}

.portrait{
  width:256px;
  height:256px;
  border-radius:20px;
  border: 0px solid transparent;
  marign:auto;
  margin-bottom:12px;
  background-color: #333;
  margin-top:0px;
}
.header-icon{
  position:absolute;
  left:6px;
  top:-1px;
  font-size:20px;
  color: #AE8E1C;
  cursor:pointer;
}
.hero-banner {
  position:relative;
  display: flex;
  flex-direction: column;
  justify-content: left;
  width:calc(100% - 32px);
  margin:auto;
  
  padding-left:32px;
  border:none;
}
.header{
  background-color: black;
    border: 1px solid black;
    border-top:none;
    border-left:none;
    border-right:none;
    color: rgba(255,255,255,0.7);
    margin-bottom: 0px;
    padding: 4px 4px;
    position:relative;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}
.header .container{
  overflow:visible;
  max-width:1280px;
  position:relative;
}
.header .back-button{
  position:absolute;
  bottom:-32px;
  left:0px;
  background:transparent;
  border:none;
  color: #AE8E1C;
  font-weight:600;
  font-size:11px;
  z-index:25;
}
.hamburger{
  font-size: 16px;
    position: absolute;
    right: 8px;
    top: 2px;
    color: white;
    cursor: pointer;
    padding: 12px;
    margin-top: -12px;
    margin-right: -12px;
    padding-bottom:24px;
}
.hamburger:hover{
  color:#e2b714;
}
.side-nav-main, .sales-modal {
  position: fixed;
  top: 0;
  right: -340px; /* Start off screen */
  width: 340px;
  height: 100%;
  background: #333;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.3);
  transition: right 0.17s ease; /* Transition for the sliding effect */
  z-index: 1000;
  padding-top:48px;
  font-size:32px;
}
.side-nav-main form{
  min-width:0px;
}
.sales-modal{
  padding-top:0px;
  width:calc(100% - 24px);
  right:0;
  left:0;
  margin:auto;
  margin-bottom:12px;
  height:320px;
  top:auto;
  bottom:-12px;
  background:rgb(36, 37, 37);
  border-radius:15px;
  transform: translateY(100%); /* Initially hidden below the screen */
  transition: transform 0.17s ease-in-out;
  text-align:center;
}
.upsell-modal-content{
  font-size:14px;
  text-align:center;
  padding-left:6px;
  padding-right:6px;
  max-width:315px;
  margin:auto;
}
.modal .upsell-modal-content{
  
}
.std-btn{
  background: #e2ba2c;
    border: none;
    border-radius: 100px;
    padding: 6px;
    padding-left: 12px;
    padding-right: 12px;
    font-weight: 600;
    margin-top:6px;
    margin-bottom:6px;
    color: #333;
}
.noCreationsMessage{
  font-family: 'Roboto', sans-serif;
  font-weight:600;
}
.noCreationsMessage p{
  margin-top:24px;
  margin-bottom:24px;
}
.upsell-modal-content form{
  max-width:330px;
  min-width:0px;
}
.onPage #checkout-and-portal-button{
  margin-left:0px;
  margin-top:6px;
}
.upsell-modal-content h2{
  font-size:32px !important;
  margin-top:12px !important;
  margin-bottom:12px !important;

    text-transform: none !important;
    
    font-family: 'Crimson Text', serif !important;
        letter-spacing: 0px !important;
    word-spacing: 0px !important;
}
.upsell-modal-content h2 svg{
  margin-bottom:-3px;
  margin-left:-3px;
  font-size:24px;
  color:#E2BA2C;
}
.upsell-modal-content span{
  color:#E2BA2C;
}
.upsell-modal-content p{
  font-family: 'Roboto', sans-serif;
  font-size:21px !important;
  font-weight:600 !important;
  margin-bottom: 16px;
    margin-top: 2px;
}
.upsell-modal-content ul{
  text-align: left;
  list-style-type: none;
  font-family: 'Roboto', sans-serif;
  font-weight:600;
  padding-left:0px;
  padding-right:0px;
  max-width:270px;
  margin:auto;
  margin-bottom:18px;
  margin-top:12px;
  font-size:16px;
}
.upsell-modal-content ul li{
  margin-top:3px;
  margin-bottom:3px;
}
.upsell-modal-content ul svg{
  margin-right:6px;
  margin-bottom:-2px;
  margin-top:2px;
}
.upsell-modal-actions button{
  background:transparent;
  border:none;
  color:white;
  font-size:16px;
  font-weight:600;
  opacity:0.8;
  margin-left:32px;
}
#checkout-and-portal-button, .ctaButton{
  padding:12px;
  opacity:1;
  font-family:'Roboto',sans-serif;
  border-radius:10px;
  font-size:19px;
  background:rgb(28 135 215) !important;
  padding-left:32px;
  padding-right:32px;
  margin-left:24px;
  border:none !important;
  color:#eee !important;
  font-weight:600;
}
.ctaButton{
  margin-left:0px;
  margin-bottom:12px;
  margin-top:12px;
}
.ctaButton.big{
  width:calc(100% - 64px);
}
.ctaButton.small{
  font-size:14px;
  padding:6px;
  padding-left:12px;
  padding-right:12px;
  margin-top:12px;
  margin-bottom:12px;
  display:inline-block;
  background:black !important;
}
.side-nav-main.visible {
  right: 0; /* Slide in */
}
.sales-modal.visible {
  bottom:0px;
  transform: translateY(0);
}

.side-nav-main a, .side-nav-main button {
  display: block;
  padding: 12px 15px;
  text-decoration: none;
  color:white;
  text-align:center;
}
.side-nav-main button{
  width: 100%;
    font-size: 32px;
    font-family: 'Crimson Text';
    background: transparent;
    border: none;
    font-weight: 600;
}

.side-nav-main-overlay, .sales-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  z-index: 999; /* Below the side nav but above everything else */
  cursor: pointer;
}

.side-nav-main-close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 24px; /* Size of the close icon */
}
.plus{
  font-size:19px;
  margin-bottom:-3px;
}
a.ccPlusMenuItem, button.ccPlusMenuItem{
  color:#E2BA2C;
}

.output-settings-container{
  text-align:center;
  padding-left:12px;
  padding-right:12px;
  padding-bottom:12px;
  padding-top:0px;
}
#submit-cc svg{
  margin-top:3px;
  margin-bottom:-3px;
  margin-left:3px;
}
.choose-output-options{
  font-family: 'Roboto', sans-serif;
  display:inline-block;
  padding:12px;
  padding-top:0px;
  font-size:13px;
  text-align:Center;
  opacity:1;
  margin:auto;
  padding-left:16px;
  padding-right:16px;
  padding-bottom:0px;
  letter-spacing:0.25px;
  word-spacing:1px;
  color:white;
  opacity:0.6;
  text-transform:lowercase;
}
.lifePathImageContainer{
  position:relative;
  width:256px;
  margin:auto;
  margin-top:12px;
}
.choose-output-options svg{
  font-size:14px;
}
.choose-output-options .cog{
  margin-bottom:-3px;
  margin-right:3px;
}
.choose-output-options:hover{
  opacity:1;
}
.character-paragraph{
  border:1px solid transparent;
  background-color: rgba(236, 169, 19,0.05);
  color:white;
  border-radius:12px;
  position:relative;
  margin-bottom:16px;
  padding:16px;
  padding-bottom:0px;
  padding-top:6px;
}

.continueWithFree, .upgradeToPlus{
  margin:12px;
  border-radius:15px;
  background:black;
  color:white;
  font-weight:600;
  padding:6px;
  padding-left:12px;
  padding-right:12px;
  border:none;
  margin-bottom:-48px;
}
.upgradeToPlus{
  padding:12px;
  padding-left:16px;
  padding-right:16px;
  font-size:16px;
  background:#E2BA2C !important;
  color:#333;
  margin-bottom:6px;
}
.upgradeToPlus b{
  border-bottom:solid 2px;
}
.upgradeToPlus svg{
  margin-bottom: -2px;
    margin-left: -2px;
    font-size: 17px;
    color: #333;
}
.superCharge{
      font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 600;
    opacity: 0.7;
    margin-bottom: 0px;
    margin-top: 24px;
}
.accordion-content{
  padding-top:6px;
}
.character-paragraph.active{
  pointer-events: none;
  opacity:0.8;
}
.character-paragraph.active p{
  opacity:0.1;
}
.character-paragraph p{
  margin-top:12px;
}
.character-paragraph:first-child{
  text-align: center;
}
.dashboard-characters .character-paragraph{
  margin-bottom:12px;
  padding:6px;
  padding-bottom:0px;
  padding-top:6px;
}
.characters-page{
  text-align:center;
}
#showAdvanced {

}
.simpleModeTickbox{
  text-align:center;
  color:white;
  font-size:16px;
  padding:2px;
  padding-top:8px;
  padding-bottom:8px;
  margin-top:6px;
  margin-bottom:6px;
  border:solid 1px rgba(255,255,255,0.2);
  border-radius:10px;
}
.simpleModeTickbox input{

}

.simpleModeTickboxContainer{
  position:absolute;
  top:75px;
  left:16px;

}
.leftColumn{
  margin-right:29px;
}
.simpleModeTickboxContainer input{
  display:inline-block;
  width: 18px;
  height: 18px;
  padding: 0;
  margin:0;
  vertical-align: bottom;
  position: relative;
  top: -6px;
      filter: grayscale(1);
      opacity:0.7;
}
.simpleModeTickboxContainer label{
  font-size:10px;
  font-weight:bold;
  font-family: 'Roboto', sans-serif;
  display:inline-block;
  margin-left:6px;
  opacity:0.7;
}
.form-settings-container:hover{
  opacity:1;
}
.form-settings-container label{
  font-family: 'Roboto', sans-serif;
  font-size:9px;
  font-weight:bold;
  letter-spacing:0.5px;
  position:absolute;
  top:3px;
  margin-left:4px;
}
.simpleModeTickbox p{
  margin:0px;
  color:white;
  opacity:0.6;
  font-size:11px;
  padding-left:6px;
  font-family: 'Roboto', sans-serif;
}
.radioButtons input[type="checkbox"]{
  margin-bottom:0px;
}   
#showAdvanced h3{
  font-weight:600;
  font-size:12px;
  color:#AE8E1C;
  padding:3px;
  border: 2px solid rgba(255,255,255,0.5);
  background-color:rgba(255,255,255,0.2);
  display: inline-block;
  padding-right: 8px;
  padding-left: 8px;
  float: right;
  margin-right: 12px;
  border-radius: 15px;
  text-decoration: none;
  opacity:0.8;
}
#hideShowButton{
  position:absolute;
  bottom:-2px;
  left:0px;
  right:0px;
  display:inline;
  text-align: center;
  text-decoration: none;

}
#hideShowButton h3{
  text-align: center;
  margin:0px;
  padding:0px;
  display:inline;
  background-color:rgba(255,255,255,0.5);
  padding:5px;
  padding-left:16px;
  padding-right:16px;
  text-decoration: none;
  opacity:1;
  color:black;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  font-size:11px;
  font-weight: 200;
}
.hero-banner p{
  max-width:1024px;
  margin-bottom:16px;
  margin-top:2px;
  font-size:10px;
  font-family: 'Roboto', sans-serif;
  font-weight:500;

}
.welcome{
  margin-top:32px;
  margin-bottom:128px;
}
.welcome h2{
  font-size:48px;
  margin-top:0px;
  margin-bottom:0px;
}
.welcome h4{
  margin-top:0px;
}
.welcome h4 span{
  color:#E2BA2C;
}
.welcome h4{
  font-size:19px;
  margin-bottom:12px;
}
.welcome h4 span svg{
  font-size:15px;
  margin-bottom:-3px;
  margin-left:1px;
}
.welcome p{
  font-family: 'Roboto', sans-serif;
  font-size:13px;
  padding-left:12px;
  padding-right:12px;
  font-weight:500;
  margin-top:0px;
  margin-bottom:32px;
}
.welcome form{
  margin-bottom:48px;
}
.welcome button{
  background: #AE8E1C;
    border: none;
    border-radius: 100px;
    padding: 6px;
    padding-left: 12px;
    padding-right: 12px;
    font-weight: 500;
    margin: auto;
    margin-bottom:6px;
    font-family: 'Roboto', sans-serif;
    font-size: 13px;
    color: black;
    cursor: pointer;
    margin-left:0px;
}
.welcome form button{
  background:transparent;
  color:white;
  opacity:0.4;
}
.welcome button:hover{
  opacity:1;
  background:#E2BA2C;
}
.smallPrint{
  font-family: 'Roboto', sans-serif;
  font-size:11px;
  font-weight:600;
  opacity:0.7;
  margin-bottom:6px;
  margin-top:6px;
}
#checkout-and-portal-button2:hover{
  opacity:0.8;
  background:transparent !important;
}
.bigGreenTick{
  display:block;
  text-align:center;
  color: #07ef78;
  font-size:64px;
  border-radius:600px;
  border:solid 4px;
  margin:auto;
  margin-bottom:32px;
  width:75px;
  height:75px;
  padding:24px;
  overflow:visible;
}

.footer{
  text-align:left;
  width:100%;
  margin-top:128px;
  margin-bottom:32px;
}

.coming-soon {
  display: flex;
  flex-direction: column;
}
.coming-soon-items {
  display: flex;
}
.roadmap-soon-items p{
  text-align: left;
  display:block !important;
}
.creations-container{
  display:grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 8px;
  min-width: 880px;

}
.creation-section{
  padding-left:6px;
  padding-right:6px;
  max-height:512px;
  overflow:hidden;
  margin-bottom:24px;

}
.creation-section p{
  font-size:14px;
  padding-top:0px !important;
  padding-bottom:12px !important;
}
.creation-section h4{
  margin-bottom:4px;
  font-size:11px;
  opacity:0.5;
}
.creation-section h3{
  margin-top:6px;
  margin-bottom:12px;
}
.creation-section img{
  width:100%;
  border-radius:5px;
  border: 2px solid rgba(255,255,255,0.5);
}
#see-more-characters{
  display:block;
  width:100%;
  text-align: center;
  margin-top:24px;
  font-weight:600;
  font-size:18px;
  text-decoration: none;

}
#see-more-characters:hover{
  text-decoration: underline;
}


h2{

}
h3{


  font-weight: 600;
  opacity:0.7;
}
.custom-field-input-container{
  padding:12px;
  width:calc(100% - 24px);
  display:flex;
}
.custom-field-input-container input{
  width:100%;
  margin-right:8px;
}
.custom-field-input-container button{
  min-width:80px;
  background:#333;
  color:white;
  border:solid 1px #AE8E1C;
  border-radius:5px;
}
.column-container h3{
  text-align: right;
  display:block;
  padding-right:14px;
}
.column-container h3.members-only-header{
  text-align: center;
  font-size:11px;
  font-weight:600;
  color:white;
  opacity:0.8;
  display:none;
}
.faq.section .coming-soon p {

  font-family: 'Roboto', sans-serif;
    opacity:0.9;
    font-size:13px;
}
.faq.section .coming-soon p b{
  font-family: 'Crimson Text', serif;
  font-size:16px;
}
#additionalDetails{

}
textarea{
  min-width:calc(100px);
  height:35px;
  max-height:240px;
}
.coming-soon h2{
}
.coming-soon p {
  margin: 5px 0;
  display:flex;
  margin:auto;
  padding:8px;
  padding-left:0px;
  padding-right:0px;
}

.loading-dots {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.dot {
  background-color: #333;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  margin: 0 5px;
  animation: loading 1s ease-in-out infinite;
}

.resetButton{
  font-family: 'Roboto', sans-serif;
  background-color: #333;
  color: #ddd;
  border: 2px solid #ddd;
  margin-left:0px;
  margin-right:0px;
  margin-top:12px;
  display:inline-block;
  float:right;
  margin-right:12px;
  border-radius:15px;
  font-weight: 600;
    font-size: 12px;
    color: #AE8E1C;
    padding: 3px;
    border: 2px solid rgba(255,255,255,0.5);
    background-color: rgba(255,255,255,0.2);
    display: inline-block;
    padding-right: 8px;
    padding-left: 8px;
    float: right;
    margin-right: 12px;
    border-radius: 15px;
    text-decoration: none;
    opacity:0.8;
    cursor:pointer;

}
.outputs-coming-soon{
  opacity:0.4;
}
.outputs-coming-soon h3{
  margin-bottom:0px;
  margin-top:12px;
      font-family: 'Crimson Text', serif;

}
.signUpHeader{
  position:absolute;
  right:4px;
  top:2px;
  padding:2px;
  padding-top:2px;
  padding-bottom:2px;
  borer-radius:5px;
  color:#AE8E1C;
  font-weight:200;
  text-decoration: none;
  font-family:monospace;
  font-size:15px;
  text-decoration: underline;
}
a{
   color:#AE8E1C;
  font-weight:600;
  text-decoration: none;
}
.signUpHeader:hover{
  color:black;
  border-bottom:solid 3px black;
}
form button{
  cursor:pointer;
}
.signup-message{
  font-family: monospace;
  max-width:500px;
  margin:auto;
  margin-top:24px;
}
.sign-up{
  width:100%;
  text-align: center;
  color:#eee;
  background:black;
  z-index:101;
  position:relative;
}
.sign-up .section h2{
 
}
.roadmap-soon-items p{
  font-size:13px;
  font-family: monospace;
}
.sign-up p{
  font-weight:600;
}
.sign-up .section{
  max-width:1024px;
  margin:auto;
  padding-top:12px;
  padding-bottom:48px;
  margin-bottom:0px;
  margin-top:0px;
}
.signup-button.big{
  background-color: transparent;
  padding:8px;
  padding-left:24px;
  padding-right:24px;
  border:solid 2px #e2ba2c;
  background-color:#e2ba2c;
  border-radius:5px;
  color:#333;
  font-weight: bold;
  font-size:19px;
  text-transform:uppercase;
}
.footer{
  text-align:center;
  color:#AE8E1C;
}
.footer .social-link{
  color:#AE8E1C;
}
.signup-button.big:hover{
  background-color:#AE8E1C;
  color:#333;
}
.social-link svg{
font-size:32px;
padding-top:6px;
}
.social-link{
  display:block;
  position:relative;
  margin-top:24px;
  margin-bottom:12px;
  color:#23272a;
  text-decoration: none;
  font-size:11px;
  font-family: monospace;
}
.sign-up .section form{
  width:100%;
  max-width:10000px !important;
  position:relative;
  display:flex;
  margin-top:24px;
}
.sign-up .section form input{
  font-size:24px;
  width:100%;
  border:solid 2px #333;
  border-radius:5px;
  padding:10px;
}
.sign-up .section form button{
  min-width:200px;
  border-radius:500px;
  margin-left:6px;
  background-color:black;
  color:white;
  border:none;
  font-size:24px;
  font-weight:600;
}
.outputModalCloseButton{

    font-family: 'Roboto', sans-serif;
    background: transparent;
    border: none;
    color: white;
    opacity: 0.6;
    font-size: 11px;
    position: absolute;
    top:10px;
    right: 10px;
    font-weight:700;
}
.add-more-modal{
  display:none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  align-items: center;
  justify-content: center;
  z-index:200;
}

.submit-button{
  background:#e2ba2c;
  border:none;
  border-radius:100px;
  padding:6px;
  padding-left:12px;
  padding-right:12px;
  font-weight:600;
  margin-top:16px;
  color:#333;
}
.add-more-modal.show{
  display:flex;
}
.namingToolTip{
  font-size:9px;
  text-align:center;
  display:block;
  margin-top:-6px;
  margin-bottom:10px;
  font-family: 'Roboto', sans-serif;
    padding-left:6px;
    padding-right:6px;
    opacity:0.6;
}
.imagePromptText{
  padding-left:12px;
  padding-right:12px;
}
.imagePromptText span{
  display:block;
  margin-top:6px;
  font-size:11px;
  font-family: 'Roboto', sans-serif;
    margin-bottom:24px;
}
.imagePromptText svg{
  position:absolute;
  font-size:12px;
  margin-left:6px;
  margin-top:4px;
  color:white;
}
.visualStyleSaveModal button{
  background: #E2BA2C;
    border: none;
    border-radius: 100px;
    padding: 6px;
    padding-left: 12px;
    padding-right: 12px;
    font-weight: 600;
    margin:auto;
    margin-top: 16px;
    color: #333;
    display:block;
}
.logout-button-container{
  position:absolute;
  display:none;
  right:6px;
  top:0px;
  margin-top:6px;
  cursor:pointer;
}
.logout-button-container button{
  background:transparent;
  border:none;
  color:white;
  opacity:0.4;
  font-weight:500;
}
.add-more-modal-content{
  background-color: #333;
  padding: 6px;
  padding-top:24px;
  padding-bottom:24px;
  border-radius: 10px;
  width: calc(100% - 64px);
  margin-top:-48px;
  max-width: 400px;
  text-align:center;
  position:relative;
  font-family:'Roboto',sans-serif;
}
.add-more-modal-content h2{
  font-size:21px;
}
.output-form{
  padding:12px;
  max-width:280px;
  margin:auto;
  
}
.reactSelect, .SelectSection{
  min-width:270px;
  width:calc(100% - 24px) !important;
  max-width:284px !important;
  margin:auto;
  font-family:'Roboto',sans-serif;
  font-size:14px;
}
.reactSelect img, .SelectSection img{
  border-radius:5px;
  min-width:50px;
  min-height:50px;
}
.SelectSection{
  max-width:10000px !important;
  width: calc(100% - 40px) !important;
  margin: 0px;
  border-radius:5px;
  border:2px solid #333;
  background-color: #ddd;
  font-size:15px;

}
.SelectSection svg{
  width:15px;
}
.SelectSection span{
  display:none;
}
.specialDropdowns{
  margin-top:6px;
  margin-bottom:12px;
  text-align: center;
  padding-left:12px;
  padding-right:12px;
}
.specialDropdowns .select-section{
  margin:auto;
}
.specialDropdowns .SelectSection{
  margin:auto;
  max-width:320px !important;
}
.specialDropdowns.random-loading .SelectSection{
  pointer-events: none !important;
  opacity:0.2 !important;
}
.specialDropdowns label{
  font-family:'Roboto',sans-serif;
  font-size:14px;
  font-weight: 700;
  text-align:center;
    opacity: 0.7;
}
.ddType{
  font-size:11px;
  margin-top:1px;
  margin-left:4px;
  padding:2px;
  padding-left:12px;
  padding-right:12px;
  background:transparent;
  border-radius:8px;
  color:white;
  font-weight:600;
}
.ddType.Setting{
  background:dimgray;
}
.ddType.Wrestling-Arena{
  background:dimgray;
}
.ddType.Micro-Location{
  background:darkslateblue;
}
.ddType.Macro-Location{
  background:lightseagreen;
}
.ddType.World{
  background:darksalmon;
}
.output-form .checkboxContainer{
  margin:auto;
  max-width:280px;
  max-height:300px;
  overflow-y:scroll;
  padding-right:6px;
}
.output-form .checkboxContainer::-webkit-scrollbar {
  background-color: rgba(255,255,255,0.2);
  width:8px;
  border-radius:50px;
}

.output-form .checkboxContainer::-webkit-scrollbar-thumb {
  background-color: rgba(255,255,255,0.2);
  border-radius:50px;

}
.output-form{
  padding-top:0px;
  margin-top:0px;
}
.output-form input{
  height: 20px;
    width: 20px;
    margin-bottom: 6px;
    border: solid 1px #AE8E1C;
    color: #AE8E1C;
    padding: 0px !important;
    min-width: auto !important;
    max-width: 100% !important;
}
.output-form input + label{
  font-family:'Roboto',sans-serif;
  font-weight:600;
  color:#AE8E1C;
  font-size: 15px;

    padding-left:3px;
    opacity: 1;

    text-decoration: none;
    
}
.output-form .checkboxContainer div{
  text-align:left;
  display:flex;
  align-items: center;
  margin-top:6px;
  margin-bottom:6px;
}
.save-success-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.save-success-modal .modal-content {
  background-color: #333;
  padding: 20px;
  border-radius: 10px;
  width: 80%;
  max-width: 400px;
}
.save-success-modal button{
  border-radius:100px;
  border:none;
  padding:4px;
  padding-left:24px;
  padding-right:24px;
  background-color:#AE8E1C;
  font-weight:bold;
  color:#333;
  margin-top:12px;
}
.column-container{
  display:block;
  margin:auto;
  margin-top:12px;
  border: 1px solid rgba(75,75,75);
  background-color:rgba(75,75,75);
  border-radius:10px;
  max-width:545px;
  width:100%;
  position: relative;
  padding-bottom:6px;
}
#created-character{
  min-height:280px;
}
.scroll-to-top {
  position: fixed;
  bottom: 2rem;
  right: 1rem;
  background-color: #333;
  color: rgb(220,220,220);
  border:solid 2px rgb(220,220,220);
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0.3s;
}

.scroll-to-top.visible {
  opacity: 1;
  visibility: visible;
}

.spinner {
  display: inline-block;
  width: 32px;
  height: 32px;
  border: 3px solid rgba(236,169,19,0.5);
  border-radius: 50%;
  border-top-color: rgba(236,169,19,1);
  animation: spin 1s ease-in-out infinite;
  margin-top:28px;
  margin-bottom:12px;
}
.character-paragraph .spinner{
  margin-top:0px;
}

.character-image-container .spinner{
  
  margin-right:-7px;
  margin-top:30px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.LoadingBox{
  padding:12px;
  padding-top:0px;
}
#CharacterParagraphLoadingSpinner{
  position:absolute;
  top:38px;
  left:calc(50% - 20px);
}
#formLoadingSpinner{
  position:absolute;
  top:48px;
  right:0px;
  left:calc(50% - 20px);
}
#imageLoadingSpinner{
  position:absolute;
  top:80px;
  right:105px;
}
.character-paragraph .LoadingBox{
  min-height:30px;
}
.loading-text{
  font-weight: 600;
  opacity:0.5;
  font-size:13px;
  margin-top:px;
}
.loading-character{
  margin-top:0px;
}
.TipCards {
  opacity: 1;
  transition: all 0.5s ease-in-out;
  font-size: 16px;
    font-weight: 600;
 background-color: rgba(236,169,19,.05);
    border: 1px solid #AE8E1C;
    border-radius: 12px;
    color: rgba(255,255,255,0.7);
    margin-bottom: 16px;
    padding: 6px 16px 0;  
    max-width:420px;
    margin:auto;
}
.tipsContainer{
  min-height:150px;
}
.tip{
  margin-top:0px;
  transition: all 0.5s ease-in-out;
}
.TipCards h3{
  transition: all 0.5s ease-in-out;
      color: #fff;
    color: #AE8E1C;
    font-family: 'Crimson Text', serif;
    font-size: 11px;
    font-weight: 600;
    margin-bottom: 12px;
    opacity: 1;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
}
.tip strong{
  color: rgba(255,255,255,1);
}
.fadeInOut {
  opacity: 0 !important;
}
.checkbox-wrapper{
  display:block;
}

.output-select{
  opacity:0.2;
}

.output-selection-section{
      border: 2px solid rgba(255,255,255,0.5);
    background-color: rgba(255,255,255,0.2);
    border-radius: 5px;
    margin-top:24px;
    margin-left:24px;
}
.output-selection-section .form-section{
  display:block;
}
#link{
  margin-top:12px;
  margin-bottom:0px;
  font-family: monospace;
  font-style: italic;
}
.response-box h3, .lifePath .character-paragraph h3, .lifePath .character-paragraph h4{
  margin-bottom:0px;
  font-family: 'Crimson Text', serif;
  text-decoration: none;
  font-weight: 600;
  font-size:12px;
  text-transform: uppercase;
  text-align: center;
  color:white;
  opacity:1;
  margin-bottom:12px;
  color:#AE8E1C;
  letter-spacing: 1px;
  word-spacing:2px;
}
.lifePath .character-paragraph h3{
  font-size:13px;
}
.lifePath .character-paragraph h4{
  font-size:11px;
}
.lifePath .character-paragraph{
  text-align:left;
  font-size: 18px;
    font-weight: 400;
}
.response-box p{
  margin-top:0px;
}
.tooltip{
  text-align: center;
  font-family: 'Roboto', sans-serif;
  font-size:12px;
  opacity:0.8;
  margin:0px;
  margin-top:-80px;
  margin-bottom:80px;
}
.custom-input{
  margin-top:6px;
}

.button-container{
  width:calc(100% - 16px);
  margin-left:8px;
  margin-bottom:12px;
  margin-top: 12px;
  text-align:center;
}
#sticky-submit{
  text-align:center;
}
#sticky-submit .button-container{
  margin:auto;
  margin-top: 0px;
  max-width:420px;
}
.random-character{
display:inline;
  background:none;
  padding:0px;
  margin:0px;
  border:none;
  color:#AE8E1C;
  font-weight:600;
  position:absolute;
  margin-top:-6px;
}
.reset{
  font-size:10px;
  font-family: 'Roboto', sans-serif;
  color:#AE8E1C;
  padding:12px;
  margin-right:0px;
  margin-bottom:0px;
}
.leftContainer{
  border: 1px solid rgba(75,75,75);
    background-color: rgba(75,75,75);
    border-radius: 10px;
    margin-top:12px;
    padding-bottom:12px;
    margin-left:12px;
    width:100%;
}
.advancedEditToggle{
  background:#e2ba2c;
  color:black;
  border:none;
  padding-left:12px;
  padding-right:12px;
  border-radius:15px;
  font-weight:600;
  opacity:0.7;
  font-size:14px;
  margin:0px;
  margin-bottom:0px;
  padding-bottom:6px;
  margin-top:12px;
  min-width:132px;
  line-height:20px;
}

.advancedEditToggle.random-loading{
  pointer-events: none;
  opacity:0.2;
}
.advancedEditToggle svg{
  color:black;
  font-size:22px;
  margin-bottom:-6px !important;
  position:relative;
}
.descriptions-container svg{
  font-size:14px;
  padding-left:4px;

  margin-bottom:-3px !important;
  display:inline-block;
}
.descriptions-container button{
  padding-top:4px;
}
.react-select{
  z-index:100;
}
.locationMessage{
  font-size:12px;
}
.locationMessage b{
  font-size:16px;
}
.locationImagePreview{
  max-width:125px;
  border-radius:15px;
  margin-top:12px;
}
.locationMessage img{
  border-radius:15px;
  max-width:125px;
  margin:auto;
  margin-top:12px;
  margin-bottom:12px;
  display:block;
  cursor:pointer;
}
.advancedEditToggle svg, .filter-toggle svg, .chatDetailToggle svg{
  margin-top:2px;
  margin-bottom:-2px;
}
form.random-loading{
  pointer-events: none;
  
}
form.random-loading .accordion{
  opacity:0.2;
}
#name{
  font-size:18px;
  padding:3px;
}
@keyframes loading {
  0% {
    transform: scale(0);
  }
  20% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
/* Safari 10.1+ */

@media not all and (min-resolution:.001dpcm) { @media {
  
    .randomize-container label{
      font-size:11px;
    }
 
@media (max-width: 1140px) {
    .get-property{
    margin-top:25px !important;
    padding-left:1px;
    padding-right:1px;
  }
}
}}
@media (max-width: 1140px) {
  .logout-button-container{
    top:-72px;
  }
  .inputs-column-full-width.flex {
    padding-left:5px;
    padding-right:5px;
    width:calc(100% - 10px);
  }
  .toggle-columns-container, .layout-button-container{
    top:6px;
  }
  .accordion-label{
    margin-bottom:12px;
  }
  .accordion{
  width:calc(100% - 10px);
  display:block;
  padding-left:5px;
  padding-right:5px;
}
  .get-property{
    margin-top:20px;
    margin-right:8px;
}
  .sign-up .section form{
    display:block;
    position:relative;
  }
  .section{
    width:calc(100% - 12px);
    padding-left:6px;
    padding-right:6px;
  }
  .sign-up .section form input{
    width:calc(100% - 48px);
    margin-bottom:6px;
  }
  .sign-up .section form button{
    width:calc(100% - 48px);
    padding:6px;
  }
  .sign-up .section p{
    padding-left:6px;
    padding-right:6px;
  }
  .column-container{
  padding-bottom:0px;
}
  .form-section{
    padding-bottom:0px;
  }
  .output-selection-section{
    display:none;
  }
  .response-section{
    width: calc(100% - 24px);
    margin-left: 12px;
    margin-right: 12px;
    max-width: 100%;

  }
  .leftContainer{
    margin-left: 12px;
    margin-right: 12px;
    width:calc(100% - 24px);
  }
  .composer-container{
    display:block;
  }
  .column-container{
    width:100%;
    max-width:100%;
    padding-left:24px;
    display:block;
  }
  form, .leftColumn{
    width:100%;
  }
  body{
    padding:0px;
  }
  .column-container{
    padding-left:6px;
  }
  .accordion-label{
    margin-left:-6px;
  }
  .form-label{
    margin-right:6px;
    font-size:14px;
    display:block;
  }
  .form-section{
    display:block;
  }
  .form-input, .form-select{
    max-width:calc(100% - 24px);
    width:100%;
  }
  .form-section textarea.form-input{
    max-width:calc(100% - 16px);
    width:100%;
  }
  .single-column .form-section textarea.form-input{
    max-width:calc(100% - 48px);
    width:100%;
  }
  .form-select{
    max-width:calc(100% - 8px);
  }
  .response-section-debug{

      margin-left:0px;
    width: 100%;

}
#cc_inputs{
  width:calc(100% - 12px);
  
}
#created-character{
  margin-top:48px;
  width: calc(100% - 24px);
}
.input-dice{
  margin-right:2px;
}

.custom-field-input-container{
  padding:0px;
  margin-top:0px;
  margin-bottom:12px;
}
.token-display{

right:110px;

}
form, .leftColumn{

  min-width:280px;

}
.viewTypeContainer{
  top:48px;

}
.simpleModeTickboxContainer{

  top:75px;


}
}
.child-characters{
  margin-top:32px;
}
.child-character-list {
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap onto multiple lines */
  align-items: flex-start; /* Align items to the top */
  justify-content: center; /* Center items horizontally */
  margin: -10px; /* Adjust for padding in child items */
}

.child-character-item {
  flex: 0 0 calc(25% - 20px); /* Each item takes up 25% of the container width, accounting for padding */
  box-sizing: border-box;
  padding: 10px; /* Space around each item */
}

.child-character-card {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.child-character-image {
  width: 100%;
  height: auto;
}

.child-character-name {
  text-align: center;
  margin-bottom: 0px;
  height: 2em; /* Fixed height to accommodate up to 2 lines */
  line-height: 2em; /* Set line height */
  overflow: hidden; /* Hide overflowing text */
  display: -webkit-box; /* Required for -webkit-line-clamp */
  -webkit-line-clamp: 1; /* Limit to 2 lines */
  -webkit-box-orient: vertical;
}

.child-character-name p {
  margin: 0;
}


/* Optional: Adjust for smaller screens */
@media (max-width: 800px) {
  .child-character-item {
    flex: 0 0 calc(50% - 20px); /* Two items per row on smaller screens */
  }
}

@media (max-width: 500px) {
  .child-character-item {
    flex: 0 0 calc(50% - 20px); /* One item per row on very small screens */
  }
}

@media (max-width: 1140px) {
  .tagsSettings{
    padding-left:10px;
    padding-right:10px;
  }
  .leftColumn, form{
    margin-right:0px;
    max-width:100%;
  }
  .style-container{
    display:none;
  }
}
@media (min-width: 1140px) {
  .typeSelectionScrollContainer{

    overflow-x: auto;
    white-space: wrap;
    -ms-overflow-style: auto;  /* IE and Edge */
    scrollbar-width: auto; 
}
  .slide.creation-section.buffer{
    display:none;
  }
  .container {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .form-section {
    width: calc(100% - 20px);
    margin: 10px;
    margin-top:0px;
    margin-bottom:12px;
  }
}
