.chat-header{
  display:flex;
  padding:12px;
  padding-top:0px;
  margin-top:-12px;
  justify-content: center;
  font-family: 'Roboto', sans-serif;
  border-bottom:solid 1px rgba(255,255,255,0.1);
  margin-bottom:12px;
  position:relative;
  flex: 0 0 auto; 
}

@media (max-width: 768px) {
  .chat-page .header {
    margin-top:-24px;
    background-color: transparent;
    border-color:transparent;
  }
}
.chat-page .footer .bottom-footer{
  display:none;
}
.startNewChat{
  position:absolute;
  right: 32px;
  top:2px;
  padding:0px;
  background:transparent;
  border:none;
  outline:none;
  font-weight:800;
  color:white;
  border:solid 1px white;
  border-radius:5px;
  padding-left:3px;
  padding-right:3px;
  opacity:0.3;
  cursor:pointer;
  font-size:10px;
}
.startNewChat:hover{
  opacity:1;
}
.chat-container{
  width:100%;
  max-width:720px;
  margin:auto;
  margin-top:24px;
  display: flex;
  flex-direction: column; /* stack children vertically */
  height: calc(100vh - 24px); 
  max-height: calc(100vh - 24px);
}
.chat-name{
  padding-right:8px;
  margin-left:-50px;
}
.editIcon, .imageIcon{
  margin-left:6px;
  margin-bottom:-2px;
  margin-top:2px;
  opacity:0.4;
  cursor:pointer;
}
.imageIcon{
  margin-left:12px;
  margin-bottom:-3px;
  margin-top:3px;
}
.editIcon:hover,.imageIcon:hover{
  opacity:1;
}
.generating-text{
  padding-left:12px;
}
.choose-char-chat{
  font-family: 'Roboto', sans-serif;
  padding: 4px;
    padding-left: 24px;
    padding-right: 24px;
    margin-left: 4px;
    margin-right: 4px;
    border: none;
    border-radius: 100px;
    font-weight: 600;
      background: #E2BA2C;
    color: #333;
    margin-top:12px;
    font-size:15px;
    display:inline-block;
}
.conversationInlineImage{
  position:relative;
  display:block;
  width:256px !important;
  height:256px !important;
  border-radius:10px !important;
  margin:auto;
  margin-top:12px;
}
.inline-edit{
  background: transparent;
  border:none;
  color:white;
  width:100%;
  padding-bottom:24px;
  height:fit-content;
  max-height:fit-content;
  overflow-y: hidden;
  resize:none;
  outline:none;
}
.cancel-edit{
  background:transparent;
  border:none;
  color:white;
  opacity:0.4;
}
.save-edit{
  font-weight:700;
  background: #E2BA2C;
  color:#333;
  border-radius:50px;
  padding:3px;
  padding-left:12px;
  padding-right:12px;
  border:none;
  margin-left:12px;
}
.chat-history{
  font-family: 'Roboto', sans-serif;
  max-height:calc(100vh - 48px);
  overflow-y: auto;
  padding-right:0px;
  padding-bottom:0px;
  padding-top:16px;
  min-height:6px;
  overflow-y: auto;
  flex: 1 1 auto;
}
.chat-history::-webkit-scrollbar{
   -webkit-appearance: none;
  width: 4px;
  background-color: transparent;
  padding:3px;
}
.chat-history::-webkit-scrollbar-thumb {
  background-color: rgba(255,255,255,0.1);
  border-radius:200px;
}
.chat-footer{
  margin-top:12px;
  padding-top:12px;
  border-top:solid 1px rgba(255,255,255,0.1);
  text-align:center;
  margin-bottom:128px;
  width:100%;
  max-width:720px;
  padding-bottom:0px;
  z-index:10;
  background-color:#333;
  flex: 0 0 auto;
  position:relative;
}
.message-input{
  margin:auto;
  display:flex;
  justify-content: center;
  width:100%;
}
.typing-indicator{
  font-family: 'Roboto', sans-serif;
  font-style:italic;
  opacity:0.6;
  font-size:12px;
  padding-top:3px;
  padding-bottom:3px;
  position:absolute;
  width:100%;
  text-align:center;
  margin-top:-36px;
}
.message-input textarea{
  width:100%;
  border-radius:10px;
  outline:none;
  border:solid 1px rgba(255,255,255,0.1);
  background:transparent;
  color:white;
  margin-left: 12px;
  min-height: 32px;
  overflow: hidden;
  height: 20px;
  font-family: 'Roboto', sans-serif;
  font-size:16px;
  padding: 6px;
  padding-left: 12px;
max-height:173px;
}
.message-input button{
  background:transparent;
  border:none;
  outline:none;
  color:white;
}
.message-input button svg{
  width:24px;
  color:#e2ba2c;
  margin-top:5px;
  margin-right:3px;
  outline:none;
  border:none;
  stroke-width:0;
}
.message-input button svg:hover{
  color:white;
}
.user {
  text-align: left;
  margin: 5px;
  padding: 10px;
  border-radius: 10px;
  display:flex;
  justify-content: right;
}
.user h4{
  text-align:right;
}
.dpCircle{
  background:black;
  border-radius:200px;
  height:40px;
  width:40px;
  margin-left:12px;
  opacity:0.25;
  min-width:40px;
  display:none;
}
.chat-mode-buttons{
  
}
.chat-mode-button{
  border-radius:10px;
  padding:4px;
  padding-left:12px;
  padding-right:12px;
  margin-left:4px;
  margin-right:4px;
    border: none;
    border-radius: 100px;
    font-weight: 600;
    background: rgba(75,75,75);
  color:rgba(255,255,255,0.7);
}
.chat-mode-button.highlighted{
  background: #E2BA2C;
  color: #333;
}
.bot {
  text-align: left;
  margin: 5px;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  align-items: center;
}
.bot h4, .user h4{
  margin:0px;
  }
.bot img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}
.side-nav img{
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}
.side-nav ul{
  margin:0px;
  list-style:none;
  padding-inline-start:0px;
  font-weight:bold;
}
.side-nav li{
  border-radius:5px;
}
.side-nav li:hover{
  background-color:black;
}
.side-nav li, .side-nav li div{
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none;
  cursor:pointer;
  display:flex;
  justify-content:left;
  height:50px;
  padding:5px;
  align-items: center; 
  position:relative;
}
.side-nav li button{
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none;
  cursor:pointer;
  position:absolute;
  right:5px;
  background:transparent;
  margin:0px;
  padding:0px;
  outline:none;
  border:none;
  color:rgba(255,255,255,0.3);
}
.side-nav li button:hover{
  color:rgba(255,255,255,1);
}
.menu-button {
  background: none;
  border: none;
  cursor: pointer;
  position:absolute;
  right:6px;
}
.darken{
  opacity:0.075;
}
.side-nav {
  position: fixed;
  top: 0;
  right: 0;
  width: 300px;
  height: 100%;
  background-color: #333;
  color: white;
  padding: 12px;
  overflow-y: auto;
  z-index: 9999;
  box-shadow: 0px 0px 12px rgba(0,0,0,0.75);
}
.side-nav h3{
  margin:0px;
  margin-top:48px;
  margin-bottom:6px;
  font-family: 'Roboto', sans-serif;
}
.side-nav input, .add-more-modal-content input{
  padding:3px;
  border-radius:5px;
  border:solid 1px transparent;
  padding-left:6px;
  padding-right:6px;
  font-weight:700;
  min-width:270px;
  max-width:calc(100% - 24px);
}
.side-nav input + button{
  background:#E2BA2C;
  border:solid 1px #E2BA2C;
  border-radius:50px;
  padding:3px;
  padding-left:12px;
  padding-right:12px;
  margin-left:6px;
  color:black;
  font-weight:700;

}
.close-button {
  background: none;
  border: none;
  cursor: pointer;
  position:absolute;
  right:6px;
  color:white;
  opacity:0.6;
}
.add-more-modal-content h3{
  margin:0px;

    font-family: 'Roboto', sans-serif;
}
.add-more-modal-content h5{
  margin-top:24px;
  margin-bottom:12px;
  opacity:0.5;
    font-family: 'Roboto', sans-serif;
    text-transform: lowercase;
}
.add-more-modal-content .detailedChatContainer h5{
  margin-top:6px;
}

.chatContext,.chatLocation{
  padding: 3px;
  padding-left:6px;
  padding-right:6px;
    border-radius: 5px;
    border: solid 1px transparent;
    min-width:270px;
    max-width:calc(100% - 24px);
    font-family:'Roboto', sans-serif;
    font-weight: 400;
    font-size:14px;
}
.chatLocation{
  margin-top:6px;

}
@media (max-width: 500px) {
  
  .chat-history.focusedMobile{
    max-height: calc(100vh - 525px);
    overflow-y:auto;
  }

}