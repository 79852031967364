.login-button-container{
  position: absolute;
    right: 32px;
    top: -2px;
}
.login-button{
  background-color: transparent;
  border:solid 1px white;
  border-radius:500px;
  color:#ECA913;
  font-weight: 900;
  font-size:11px;
  letter-spacing:0.25px;
  text-transform: uppercase; 
  background-color:white;
  color:#333;
  opacity:1;
  font-family: 'Roboto', sans-serif;
  padding:1px;
  padding-left:6px;
  padding-right:6px;
}
.login-button:hover{
  background-color:#ECA913;
  border:solid 1px #ECA913;
  color:#333;
}
.forgot-password-button{
  background:transparent;
  border:none;
  color:white;
  opacity:0.6;
  font-size:11px;
  font-family: 'Roboto', sans-serif;
  display:block;
  
  text-align:left;
  padding-left:32px;
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.85);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index:201;
}
.modal .close{
  background:transparent;
  border:none;
  color:white;
  opacity:0.7;
  font-size:15px;
  font-weight:700;
  padding:6px;
  position:absolute;
  right:6px;
  top:6px;
}
.modal input{
  display:block;
  margin:auto;
  margin-top:12px;
  margin-bottom:6px;
  width:calc(100% - 80px);
  font-size:19px;
  border:solid 0px #ECA913;
  outline:none;
  border-radius:5px;
  padding:6px;
}
.modal p{
  font-weight: 400;
}
.modal h2{
  margin-top:12px;
  margin-bottom:12px;

    font-size: 24px;
    font-family: Crimson Text;

}
.already-have-account{
  font-size:11px !important;
}

.modal .sign-up-button{
  margin-top:12px;
  margin-bottom:24px;
  font-size:19px;
  padding:6px;
  border:solid 2px #ECA913;
  background-color:#ECA913;
  border-radius:5px;
  width:calc(100% - 48px);
}
.modal .close:hover{
  opacity:1;
}
.modal-content {
  background-color: #333;
  padding: 6px;
  border-radius: 15px;
  width: calc(100% - 48px);
  margin-top:-100px;
  max-width: 400px;
  text-align:center;
  position:relative;
  padding-top:12px;
  padding-bottom:24px;
}

.error-message {
  padding-top:48px;
  padding-bottom:12px;
  font-weight:600 !important;
  color: orange;
}
.token-reset-message{
  margin-top:-9px;
  margin-bottom:28px;
}
.token-reset-message a{
  font-family: 'Roboto', sans-serif;
      background: #E2BA2C;
    border: none;
    border-radius: 100px;
    padding: 6px;
    padding-left: 24px;
    padding-right: 24px;
    font-weight: 600;
    margin: auto;
    margin-top: 16px;
    color: #333;
    display: inline-block;
}
.token-reset-message a.dismiss{
  padding:0px;
  font-size:13px;
  color:white;
  opacity:0.4;
  background:transparent;
  font-weight:400;
  text-transform: lowercase;
}
@media (max-width: 819px) {
.login-button{
  font-size:9px;
}
  }
