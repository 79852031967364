/* Characters.module.css */
.gridContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  grid-gap: 24px;
  padding-top:24px;
}

.gridContainer .cardContent h3, .cardContent h3{
  font-family: 'Crimson Text', serif;
    text-decoration: none;
    font-weight: 600;
    font-size: 11px;
    text-transform: uppercase;
    text-align: center;
    color: white;
    opacity: 1;
    color: #AE8E1C;
    margin-top:6px;
}
.character-paragraph p{
  margin:0px;
  padding-top:8px;

}
.card {
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  max-width:280px;
  margin:auto;
  display: flex;
  flex-direction: column;
  position: relative;
  text-align: center;
  position: relative;
    background-color: #3D3D3D;
    border-radius: 10px;
    padding: 12px;
    padding-bottom:12px;
    margin-bottom: 12px;
    transition: all 0.3s;
    height:fit-content;
}

.singleView .card, .card.inModal{
  max-width:600px;
  width:calc(100% - 48px);
}
.editButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #AE8E1C;
  border: none;
  cursor: pointer;
  padding: 5px;
  padding-left:10px;
  padding-right:10px;
  border-radius: 5px;
  font-weight:900;
  letter-spacing:0.25px;
  z-index:10;
  color:#333;

}
.logoutButton{
  float:right;
  border: solid 1px rgb(220,220,220);
    border-radius: 5px;
    color: #AE8E1C;
    font-weight: bold;
    font-size: 11px;
    text-transform: uppercase;
    background-color: rgb(220,220,220);
    color: #333;
}
.deleteButton{
  position: absolute;
  top: 6px;
  left: 48px;
  background-color: #333;
  border: none;
  cursor: pointer;
  padding: 5px;
  font-size:10px;
  text-transform: uppercase; 
  font-weight:900;
  color:white;
  border-radius: 5px;
  z-index:10;
  opacity:0.6;
  letter-spacing:0.25px;
}
.editButtonBottom {
  background-color: #AE8E1C;
  border: none;
  cursor: pointer;
  padding: 5px;
  border-radius: 5px;
  z-index:10;
  color:#333;
  display:inline-block;
  position: relative;
  font-weight: bold;
  max-width:100px;
  min-width:100px;
  float:right;
  right:0px;
  margin:auto;
}

.card img {
  width: 100%;
  height: auto;
  object-fit: cover;
  border: 0px solid transparent;
    border-radius: 20px;
    width: 100%;
    max-width:250px;
    margin-left:auto;
    margin-right:auto;
    margin-bottom:12px;
    cursor:pointer;
}

.card h3 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.card p {
  
  flex-grow: 1;
}

.cardContent {
  max-height: 125px;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
}
.cardContent p{
  line-clamp: 3;
}
.cardContent.fullHeight p{
  line-clamp: none;
  font-weight:600;
}
.cardContent.fullHeight {
  max-height: none;
}
.toggleView{
  position: absolute;
  right:6px;
  margin-top:-16px;
  background:transparent;
  color:white;
  border:none;
  border-radius:10px;
  padding-left:12px;
  padding-right:12px;
  opacity:0.5;  
}
.toggleView:hover{
  opacity:1;
}

.toggleHeightButton {
  position: absolute;
  bottom: 4px;
  right: 4px;
  padding: 8px;
  padding-bottom:0px;
  background-color: #333;
  padding:2px;
  border-radius:100px;
  height:22px;
  width:22px;
  margin:auto;
  padding-top:2px;
  border: none;
  cursor: pointer;
  color: #AE8E1C;
  z-index:10;
  font-size:18px;
}
.toggleHeightButton:hover{
  opacity:1;
  color:#AE8E1C;
}
.listContainer {
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding-top:18px;
}

.listItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;
  justify-content: flex-start;
  margin: 0; 
  padding: 0; 
  border:solid 1px rgba(255,255,255,0.2);
  border-radius:10px;
  padding:2px;
}

.listItem h3{
  font-size:14px;
}


.listContainer img{
  height:50px;
  width:50px;
  border-radius:10px;
}

.listContainer .cardContent{
  display:none;
}

.listContainer .editButton {
  position: relative;
  margin-top:-24px;
  background-color: #AE8E1C;
  border: none;
  cursor: pointer;
  padding: 5px;
  padding-left:10px;
  padding-right:10px;
  border-radius: 5px;
  font-weight:bold;
  z-index:10;
  color:#333;
  float:right;
}
.listContainer .toggleHeightButton{
  display:none;
}
.listContainer .deleteButton{
  position: relative;
  margin-top:-24px;
} 

